<template>
  <Layout>
    <PageHeader title="Add Location Type" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="card border-radius-12 border">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row flex-column">
                  <div> <p class=" fw-bold">Select Existing Icon</p> </div>
                  <section  class=" free_listing-banner d-flex align-items-center justify-content-center ms-2">    
                  <div class="col-md-6">
                      <!-- <label class="form-label" for="image"
                        >Select Location Type Image</label -->                    
                        <div class="pointer text-center" @click="openPopup" v-if="!form.icon"> 
                        <img src="@/assets/images/AddUser.svg" alt="Add User">
                          <span class="f-16 fw-400">Upload Icon</span>
                      </div>
                    <div v-if="form.icon" class="text-center">
                  <img :src="form.icon" alt="Selected Icon" class="img-fluid" />
                   </div>
                    <input type="hidden" v-model.trim="$v.form.icon.$model" />
                  </div>
                </section>
                <div v-if="$v.form.icon.$error">
                        <div class="error" v-if="$v.form.icon.$error">
                          Icon is Required!
                        </div>
                      </div>
                      <div class="col-md-4">
                    <div class="my-3">
                      <label class="form-label fw-bold" for="formrow-Lastname-input"
                        >Location Type Slug</label
                      >
                      <input
                        type="text"
                        class="form-control  rounded-pill"
                        id="formrow-Lastname-input"
                        v-model.trim="$v.form.slug.$model"
                        placeholder="Enter Location Type Slug"
                      />
                      <div v-if="$v.form.slug.$error">
                        <div class="error" v-if="$v.form.slug.$error">
                          Slug is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.slug.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="my-3">
                      <label class="form-label fw-bold" for="formrow-Firstname-input"
                        >Location Type Name</label
                      >
                      <input
                        type="text"
                        class="form-control  rounded-pill"
                        id="formrow-Firstname-input"
                        v-model.trim="$v.form.title.$model"
                        placeholder="Enter Location Type Name"
                      />
                      <div class="error" v-if="$v.form.title.$error">
                        <div class="error" v-if="$v.form.title.$error">
                          Title is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.title.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end gap-3"> 
      <div class="cta_submit button text-end border-orange fit-content rounded-pill p1 float-end">
        <SubmitButton
          :processing="processing"
          type="submit"
          class="btn btn-primary waves-effect waves-light w-md"
          text="Add"
        />
      </div>
      <div class="cta_submit button text-end border-dark fit-content rounded-pill p1 float-end">
        <SubmitButton
           @clicked="resetForm"
          type="button"
          :color='"btn waves-effect waves-light w-md bg-black py-2"' 
          text="Cancel"
        />
      </div>
    </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="category-modal" hide-footer title="Select Icon" size="xl">
      <p v-if="iconSelection">Please select at least one icon</p>
      <APILoader
        v-if="loading"
        :loading="loading"
        class="align-middle text-center"
      ></APILoader>
      <div
        v-else
        class="row text-center"
      >
        <div
          v-for="(img, index) in images"
          :key="index"
          @click="selectImg(index, img)"
          :class="[
            'd-flex',
            'flex-column',
            'justify-content-center',
            'gap-0',
             'col-2',
            { 'img-category': selectedIndex === index },
            'cursor-pointer',
          ]"
        >
          <img
            :src="img.url"
            class="img-fluid mx-auto"
            alt="category-image"
            height="30px"
            width="30px"
          />
          <p class="mb-0 w-75 mx-auto">{{ img.name }}</p>
        </div>
      </div>
      <div
        class="d-flex justify-content-end border-orange fit-content rounded-pill p1 mt-4 cat-btn"
      >
        <b-button
          class="bg-orange rounded-pill text-white f-16 px-4 py-2"
          variant="outline-danger"
          block
          @click="hideModal"
          >Select Icon</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required, alpha } from "vuelidate/lib/validators";
export default {
  name: "add-sub-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      processing: false,
      buttonTypeVal: "Select an Location Type image",
      loading: true,
      images: [],
      iconSelection: false,
      form: {
        title: null,
        slug: null,
        icon: null,
      },
      selectedIndex: false,
    };
  },
  validations: {
    form: {
      title: {
        required,
        // alpha,
      },
      slug: {
        required,
        // alpha,
      },
      icon: {
        required,
      },
    },
  },
  mounted() {
    this.allCategory();
  },
  methods: {
    selectImg(index, img) {
      this.iconSelection = false;
      this.selectedIndex = index;
      this.form.icon = img.url;
    },
    openPopup() {
      this.$refs["category-modal"].show();
      if (this.images.length < 1) {
        this.categoryImages();
      }
    },
    hideModal() {
      if (this.form.icon) {
        this.iconSelection = false;
        return this.$refs["category-modal"].hide();
      }
      this.iconSelection = true;
    },
    resetForm(){
      this.form.icon= null;
      this.form.slug= null;
      this.form.title= null;
    },
    categoryImages() {
      this.$axios
        .get("category-images")
        .then((response) => {
          this.images = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        console.log(this.form);
        this.$axios
          .post("location-type", this.form)
          .then((response) => {
            this.$router.push({ path: "/location-type/listing" });
            this.triggerSwal(response.data.message, "success");
            console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            console.log(error.response);
            this.processing = false;
          });
      }
    },
  },
};
</script>
